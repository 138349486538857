
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core//mixins/mixin";
import { NavBar, Row, Image } from "vant";
import ItemTitle from "./ItemTitle.vue";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Image.name]: Image,
    ItemTitle,
  },
})
export default class GoodsInfo extends Mixins(Mixin) {
  @Prop({
    type: Boolean,
    default: true,
  })
  public isBorder!: boolean;
  @Prop({
    type: String,
    default: "",
  })
  public addrName!: string;
  @Prop({
    type: String,
    default: "",
  })
  public addrMobile!: string;
  @Prop({
    type: String,
    default: "",
  })
  public receivingAddress!: string;

  info: member.MyAddrRes = {};
  // @Watch("defaultAddress", { immediate: true, deep: true })
  // updateAddress(): void {
  //   this.info = this.defaultAddress;
  // }

  dealNumber(addrMobile: string): string {
    return addrMobile.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2") || "";
  }
}
