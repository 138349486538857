
import { Component, Mixins } from "vue-property-decorator";
import {
  NavBar,
  NoticeBar,
  Image,
  Swipe,
  SwipeItem,
  Dialog,
  CountDown,
} from "vant";
import { Mixin } from "@/core/mixins/mixin";
import NoLottery from "@/views/LimitActivities/components/RedEnvelopeRain/NoLottery.vue";
import PrizeLevel from "@/views/LimitActivities/components/RedEnvelopeRain/PrizeLevel.vue";
import FastClick from "fastclick";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [NoticeBar.name]: NoticeBar,
    [Image.name]: Image,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Dialog.Component.name]: Dialog.Component,
    [CountDown.name]: CountDown,
    NoLottery,
    PrizeLevel,
  },
})
export default class RedEnvelopeRain extends Mixins(Mixin) {
  id = "";
  showPrivacy = false;
  liParams: Array<{
    left?: string;
    cls?: string;
    transforms?: string;
    durTime?: string;
    activeId: number;
  }> = []; //红包item数组
  timer = 0; //红包下落
  duration = 0; // 游戏时间
  countdown = 3; //倒计时3秒
  priczeNum = 0; //点击红包的个数
  prizeInfo: cms.ActivityInfoDto = { menuType: "" };
  isShow = true;
  selectedItems: Array<number> = []; //点击红包消失id数组
  activeItems: Array<number> = []; //点击红包切换图片的数组
  itemId = 0; //初始红包下标0开始计算
  levelData: cms.RedPacketWinPrizeDto = {}; //奖品
  mounted(): void {
    FastClick.attach(document.body);
    this.id = this.$route.query.id ? String(this.$route.query.id) : "";
    if (this.id) {
      this.getInfo();
      this.startRain();
    }
  }

  /*
   * 活动信息
   */
  getInfo(): void {
    this.$api.cmsApi.lucky.findActivityInfoById(
      this.id,
      ({ data }) => {
        this.duration = data.gameTime ? data.gameTime * 1000 : 0;
        this.prizeInfo = data;
      },
      (error) => {
        if (error.includes("@_@")) {
          this.$toast(error.split("@_@")[1]);
        } else {
          error && this.$toast(error);
        }
      }
    );
  }

  /*
   * 五秒倒计时
   */
  startRain(): void {
    let timer = setInterval(() => {
      this.countdown = this.countdown - 1;
      if (this.countdown < 0) {
        this.isShow = false;
        clearInterval(timer);
        (this.$refs.countDown as CountDown).start();
        this.startRedPacket();
        return;
      }
    }, 1000);
  }
  onClickLeft(): void {
    this.$router.replace(`/red-envelope?id=${this.id}`);
  }
  onClick(id: number): void {
    if (this.selectedItems.includes(id) || this.activeItems.includes(id)) {
      //如果当前红包以点击就禁止连续点击
      return;
    }
    this.priczeNum++;
    this.activeItems.push(id);
    let timer = setTimeout(() => {
      //设置定时器当红包展示打开过程后才消失。
      this.selectedItems.push(id);
      clearTimeout(timer);
    }, 200);
  }
  /*
   * 再来一次
   */
  goAgain(): void {
    // this.$router.replace(`/red-envelope?id=${this.id}`);
    this.$router.go(-1);
    this.showPrivacy = false;
  }
  /*
   * 去兑换
   */
  goExchange(): void {
    let data = {
      commodityInfoId: this.levelData.activityPrizeDto
        ? this.levelData.activityPrizeDto.trophyId
        : "",
      num: 1,
      winningId: this.levelData.winningId || "",
      type: this.levelData.winningType,
    };
    //  该功能属于新需求，延期处理 bug号： http://172.16.50.102:8080/browse/BLPWB-10008
    this.$router.replace(`/commodity-exchange?data=${JSON.stringify(data)}`);
  }
  /**
   * 开启动画
   */
  startRedPacket(): void {
    let win = document.documentElement.clientWidth || document.body.clientWidth;
    let left = Math.random() * (win - 50) + 0;
    let rotate = 0; // 旋转角度
    let scales = (Math.random() * (12 - 8 + 1) + 8) * 0.1; // 图片尺寸
    let durTime = Math.random() * (2.5 - 1.2 + 1) + 2 + "s"; // 时间 1.2和1.2这个数值保持一样
    this.itemId++;
    this.liParams.push({
      left: left + "px",
      cls: "move_1",
      transforms: "rotate(" + rotate + ") scale(" + scales + ")",
      durTime: durTime,
      activeId: this.itemId,
    });

    let onceTime =
      this.duration /
      (this.prizeInfo.redPacketNum
        ? this.prizeInfo.redPacketNum
        : this.duration);
    this.timer = Number(
      setTimeout(() => {
        this.startRedPacket();
      }, onceTime) //多少时间执行一次
    );
  }

  /**
   * 倒计时结束
   */
  finish(): void {
    // 多少时间结束
    clearTimeout(this.timer);
    if (this.priczeNum === 0) {
      this.showPrivacy = true;
      this.levelData.winning = false;
      return;
    }
    this.getPrize();
  }

  /**
   * 时间结束看是否中奖
   */
  getPrize(): void {
    let data = {
      activeInvitationCode: this.prizeInfo.activityInvitationCode
        ? this.prizeInfo.activityInvitationCode
        : "",
      activityId: this.id,
      redPacketNum: this.priczeNum,
    };
    this.$api.cmsApi.lucky.redPacketWinPrize(data, ({ data }) => {
      if (data) {
        this.levelData = data;
        this.showPrivacy = true;
      }
    });
  }

  /**
   * 判断是能否能再来一次
   */
  getType(levelData: cms.RedPacketWinPrizeDto): number {
    let type = 2;
    if (levelData.luckDrawTimes === -1) {
      //为-1时表示可以无限抽奖
      type = 1;
    } else {
      if (levelData.luckDrawTimes) {
        let num = levelData.luckDrawTimes - 1;
        if (num > 0) {
          type = 1;
        } else {
          type = 2;
        }
      }
    }

    return type;
  }

  /**
   * 回收dom节点
   */
  removeDom(e: { currentTarget: Element }): void {
    let target = e.currentTarget;
    if (target) {
      (document.querySelector("#red_packet") as Element).removeChild(target);
    }
    return;
  }
}
