
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins } from "vue-property-decorator";
import {
  NavBar,
  Row,
  Col,
  Icon,
  Image,
  List,
  Cell,
  Button,
  Field,
  CellGroup,
} from "vant";
import goodList from "@/views/Mine/MoreServices/CollectionPrinting/goodList.vue";
import GoodsInfo from "@/views/Mall/Payment/GoodsInfo.vue";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Image.name]: Image,
    [List.name]: List,
    [Cell.name]: Cell,
    [Button.name]: Button,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    goodList,
    GoodsInfo,
  },
})
export default class ExchangeConfirm extends Mixins(Mixin) {
  public goodsInfo = {
    image: "",
    title: "澳洲进口fiveram洗衣液天然萃取温和不伤皮肤保护娇嫩双手",
    num: 2,
    print: 20,
    totalPrint: 20,
  };
  public message = "";
  public isPrint = false;
  public pickUpType = "01";
  public form = {
    deliveryWay: "01",
  };
  created(): void {
    // this.getOrderDetail();
  }
  onClickLeft(): void {
    this.$router.go(-1);
  }
  getOrderDetail(): void {
    // 接口位置
  }
  exchangeConfirm(): void {
    // this.$toast("兑换");
    this.$router.push("/print-order-detail");
  }
}
