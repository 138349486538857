import { render, staticRenderFns } from "./PrizeLevel.vue?vue&type=template&id=3a709d86&scoped=true"
import script from "./PrizeLevel.vue?vue&type=script&lang=ts"
export * from "./PrizeLevel.vue?vue&type=script&lang=ts"
import style0 from "./PrizeLevel.vue?vue&type=style&index=0&id=3a709d86&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a709d86",
  null
  
)

export default component.exports