
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import wx from "weixin-js-sdk";
import {
  Row,
  Button,
  Field,
  NavBar,
  Image as VanImage,
  Icon,
  Step,
  Steps,
  Col,
} from "vant";
@Component({
  components: {
    Mixin,
    [Row.name]: Row,
    [Button.name]: Button,
    [Field.name]: Field,
    [NavBar.name]: NavBar,
    [VanImage.name]: VanImage,
    [Icon.name]: Icon,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [Col.name]: Col,
  },
})
export default class goodList extends Mixins(Mixin) {
  @Prop({
    default: () => {
      return [];
    },
  })
  public RecordList!: Array<member.GetCommodityInfoResponse>;
  @Prop({ default: "" }) public activityId!: string;
  goDetail(id: number | undefined): void {
    if (this.ChannelModule.channel === "plat_h5") {
      this.$router.push(
        `/mall/details?commodityId=${Number(id)}&activityOrderId=${
          this.activityId
        }`
      );
    } else if (this.ChannelModule.channel === "plat_wechat_miniapp") {
      wx.miniProgram.navigateTo({
        url: `/pagesMall/goodsDetails/goodsDetails?commodityInfoId=${id}&activityOrderId=${this.activityId}`,
      });
    } else {
      this.jsBridge(
        "goMallGoodDetail",
        `commodityInfoId/${id},activityId/${this.activityId}`
      );
    }
  }
}
