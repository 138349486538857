
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Row, Image, Icon } from "vant";
import { Mixin } from "@/core/mixins/mixin";

@Component({
  components: {
    [Row.name]: Row,
    [Image.name]: Image,
    [Icon.name]: Icon,
  },
})
export default class NoLottery extends Mixins(Mixin) {
  @Prop({
    type: Number,
    default: () => {
      return 2;
    },
  })
  public type!: number;
  @Prop({
    type: Object,
    default: () => {
      return {};
    },
  })
  public prizeInfo!: cms.ActivityInfoDto;

  isShow = false;
  /*
   * 再来一次
   */
  goAgain(): void {
    this.$emit("goAgain");
  }
}
