var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"r-e-rain"},[(_vm.ChannelModule.channel === 'plat_h5')?_c('van-nav-bar',{attrs:{"fixed":"","title":_vm.prizeInfo.activityName,"border":false,"placeholder":true},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('van-image',{attrs:{"fit":_vm.imageFit,"width":"0.6rem","height":"0.6rem","src":require('@/assets/images/LimitActivities/l2.png')},on:{"click":function($event){$event.preventDefault();return _vm.onClickLeft.apply(null, arguments)}}})]},proxy:true}],null,false,1582264873)}):_vm._e(),_c('van-image',{staticStyle:{"z-index":"-1","position":"fixed","left":"0","top":"0"},attrs:{"fit":_vm.imageFit,"width":"100%","height":"100%","src":_vm.prizeInfo.guideImageUrl}}),_c('div',{staticClass:"r-top"},[_c('div',{staticClass:"top-left"},[_c('van-image',{attrs:{"fit":_vm.imageFit,"width":"0.61rem","height":"0.7rem","src":require('@/assets/images/LimitActivities/a1.png')}}),_c('span',[_vm._v("剩余时间"),_c('van-count-down',{ref:"countDown",attrs:{"time":_vm.duration,"auto-start":false,"format":"ss"},on:{"finish":_vm.finish}}),_vm._v(" 秒 ")],1)],1),_c('div',{staticClass:"top-right"},[_c('van-image',{attrs:{"fit":_vm.imageFit,"width":"0.6rem","height":"0.7rem","src":_vm.prizeInfo.redPacketImageNoClick}}),_c('span',[_vm._v(" 已获得"),_c('span',{staticStyle:{"font-size":"0.42rem","margin":"0 0.05rem"}},[_vm._v(" "+_vm._s(_vm.priczeNum)+" ")]),_vm._v("个")])],1)]),_c('ul',{staticClass:"red_packet",attrs:{"id":"red_packet"}},_vm._l((_vm.liParams),function(item,index){return _c('li',{key:index,staticClass:"move_1",class:{ active: _vm.selectedItems.includes(item.activeId) },style:({
        left: item.left,
        animationDuration: item.durTime,
      }),attrs:{"data-index":index},on:{"webkitAnimationEnd":_vm.removeDom,"click":function($event){$event.preventDefault();return _vm.onClick(item.activeId)}}},[_c('span',[_c('i',{style:({
            transform: item.transforms,
            'background-image':
              'url(' +
              (_vm.activeItems.includes(item.activeId)
                ? _vm.prizeInfo.redPacketImageClick
                : _vm.prizeInfo.redPacketImageNoClick) +
              ')',
          })})])])}),0),_c('van-dialog',{attrs:{"confirm-button-text":"我知道了","show-confirm-button":false},model:{value:(_vm.showPrivacy),callback:function ($$v) {_vm.showPrivacy=$$v},expression:"showPrivacy"}},[(!_vm.levelData.winning)?_c('NoLottery',{attrs:{"type":_vm.getType(_vm.levelData),"prize-info":_vm.prizeInfo},on:{"goAgain":_vm.goAgain}}):_c('PrizeLevel',{attrs:{"type":_vm.getType(_vm.levelData),"activity-prize-dto":_vm.levelData.activityPrizeDto},on:{"goAgain":_vm.goAgain,"goExchange":_vm.goExchange}}),_c('div',{staticClass:"r-bottom"},[_c('van-image',{attrs:{"fit":_vm.imageFit,"width":"0.48rem","height":"0.48rem","src":require('@/assets/images/LimitActivities/r3.png')},on:{"click":function($event){$event.preventDefault();_vm.showPrivacy = false}}})],1)],1),(_vm.isShow)?_c('div',{staticClass:"countdown"},[_c('span',[_vm._v(" "+_vm._s(_vm.countdown > 0 ? _vm.countdown : "开始"))])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }